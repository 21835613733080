import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import {
  Box,
  Tag,
  Text,
  Image,
  Center,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Progress,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import {
  useReservationsQuery,
  ReservationFieldsFragment,
} from '~graphql/generated'
import { useScroll } from '~context/scroll-provider'
import ServerDown from '~images/illustrations/server-down.svg'

interface Props {
  isCanceled: boolean
}

const ReservationsTable: React.FC<Props> = ({ isCanceled }) => {
  const { isBottom } = useScroll()
  const [limit, setLimit] = useState(20)
  const [reservations, setReservations] = useState<ReservationFieldsFragment[]>(
    []
  )
  const theadBgColor = useColorModeValue('gray.50', 'gray.800')

  const { data, loading, error } = useReservationsQuery({
    variables: { limit, cancelledDateNull: !isCanceled },
    onCompleted: data =>
      setReservations(data.reservations as ReservationFieldsFragment[]),
  })

  useEffect(() => {
    if (data) setReservations(data.reservations as ReservationFieldsFragment[])
  }, [data])

  useEffect(() => {
    if (isBottom && limit <= reservations.length) setLimit(limit + 20)
  }, [isBottom])

  return (
    <>
      <Text ml={6} my={2} fontSize="sm" fontWeight="semibold">
        {loading ? 'Loading...' : `${reservations.length} reservations`}
      </Text>

      <Box overflow="auto" pos="relative">
        <Table variant="striped">
          <Thead bgColor={theadBgColor} pos="sticky" top={0}>
            <Tr>
              <Th w="50px" pl={6}>
                #
              </Th>
              <Th minW="200px" w="400px">
                Hotel
              </Th>
              <Th w="300px">Room</Th>
              <Th>Status</Th>
              <Th minW="100px">Booked</Th>
              <Th minW="100px">In</Th>
              <Th minW="100px">Out</Th>
              <Th>Customer</Th>
            </Tr>
          </Thead>
          <Tbody>
            {reservations.map(reservation => {
              const {
                id,
                user,
                reservation_hotel,
                reservation_room,
                cancelled_date,
              } = reservation

              return (
                <Tr
                  key={id}
                  fontSize="sm"
                  cursor="pointer"
                  onClick={() => navigate(`/reservations/${id}`)}
                >
                  <Td pl={6}>{id}</Td>
                  <Td>
                    <Box>
                      <Text fontWeight="bold" noOfLines={1} mb="2px">
                        {reservation_hotel?.hotel_name}
                      </Text>
                      <Text noOfLines={1}>
                        {reservation_hotel?.hotel_address?.city},{' '}
                        {reservation_hotel?.hotel_address?.state ||
                          reservation_hotel?.hotel_address?.postal_code}
                      </Text>
                    </Box>
                  </Td>
                  <Td>
                    <Text noOfLines={1}>
                      {reservation.rooms} {reservation_room?.room_name}
                    </Text>
                  </Td>
                  <Td>
                    {cancelled_date ? (
                      <Tag size="sm" colorScheme="red">
                        Canceled
                      </Tag>
                    ) : (
                      <Tag size="sm" colorScheme="green">
                        Active
                      </Tag>
                    )}
                  </Td>
                  <Td>
                    <Text mb="2px">
                      {format(new Date(reservation.created_at), 'MM/dd/yy')}
                    </Text>
                    <Text fontWeight="semibold" textTransform="uppercase">
                      {format(new Date(reservation.created_at), 'h:mm aaa')}
                    </Text>
                  </Td>
                  <Td>
                    <Text mb="2px">
                      {reservation.in_date &&
                        format(new Date(reservation.in_date), 'MM/dd/yy')}
                    </Text>
                    <Text fontWeight="semibold">{reservation.in_time}</Text>
                  </Td>
                  <Td>
                    <Text mb="2px">
                      {reservation.out_date &&
                        format(new Date(reservation.out_date), 'MM/dd/yy')}
                    </Text>
                    <Text fontWeight="semibold">{reservation.out_time}</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Avatar
                        size="sm"
                        shadow="lg"
                        src={user?.profile_img?.url}
                        name={`${user?.first_name} ${user?.last_name}`}
                      />
                      <Text>{user?.first_name}</Text>
                    </HStack>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>

      {error && (
        <Center py={16} flexDir="column">
          <Image src={ServerDown} w="240px" />
          <Text mt={4} fontSize="sm">
            {error.name}: {error.message}
          </Text>
        </Center>
      )}

      <Progress h="2px" isIndeterminate={loading} colorScheme="brand" />
      <Text ml={6} mt={4} mb={2} fontSize="sm" fontWeight="semibold">
        {`${reservations.length} reservations`}
      </Text>
    </>
  )
}

export default ReservationsTable
