import React from 'react'
import { Router } from '@reach/router'

import ReservationsPage from '~components/pages/reservations/reservations-page'
import ReservationDetailsPage from '~components/pages/reservations/reservation-details-page'

const ReservationsPageRouter = () => {
  return (
    <Router basepath="/reservations">
      <ReservationsPage path="/" />
      <ReservationDetailsPage path=":id" />
    </Router>
  )
}

export default ReservationsPageRouter
