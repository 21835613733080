import React from 'react'
import {
  Text,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { ReservationFieldsFragment } from '~graphql/generated'

import { useCancelReservation } from '~hooks/queries'

interface Props {
  reservation: ReservationFieldsFragment
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

const CancelationModal: React.FC<Props> = ({
  reservation,
  isOpen,
  onClose,
  refetch,
}) => {
  const toast = useToast()

  const { mutate: cancelReservation, isLoading } = useCancelReservation(
    reservation.id,
    reservation.itinerary_id || '',
    {
      onSuccess: () => {
        refetch()
        onClose()
        toast({
          status: 'success',
          title: 'Reservation canceled',
          isClosable: true,
          duration: 8000,
        })
      },
      onError: error => {
        toast({
          status: 'error',
          title: (error as Error).name,
          description: (error as Error).message,
          isClosable: true,
          duration: 8000,
        })
      },
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">Canceling reservation</ModalHeader>
        <ModalCloseButton top={3} />
        <Divider />
        <ModalBody mt={4}>
          <Text>Are you sure to proceed with canceling this reservation?</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="red"
            isLoading={isLoading}
            onClick={() => cancelReservation()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelationModal
