import React, { useMemo } from 'react'
import querystring from 'query-string'
import { navigate } from 'gatsby'
import { RouteComponentProps, useLocation } from '@reach/router'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabProps,
} from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ReservationsTable from './table'

const ReservationsPage: React.FC<RouteComponentProps> = () => {
  const { search } = useLocation()
  const { status } = querystring.parse(search)

  const tabIndex = useMemo(() => {
    if (status === 'canceled') return 1
    return 0
  }, [status])

  const tabProps: TabProps = {
    borderBottomWidth: '4px',
    _selected: {
      fontWeight: 'bold',
      borderColor: 'brand.300',
    },
  }

  return (
    <>
      <Seo title="Reservations" />
      <Heading>Reservations</Heading>

      <Tabs index={tabIndex} isLazy>
        <TabList mb={4}>
          <Tab {...tabProps} onClick={() => navigate('/reservations')}>
            Active
          </Tab>
          <Tab
            {...tabProps}
            onClick={() => navigate('/reservations?status=canceled')}
          >
            Canceled
          </Tab>
        </TabList>
        <ContentBox p={0} py={4}>
          <TabPanels>
            <TabPanel p={0}>
              <ReservationsTable isCanceled={false} />
            </TabPanel>
            <TabPanel p={0}>
              <ReservationsTable isCanceled={true} />
            </TabPanel>
          </TabPanels>
        </ContentBox>
      </Tabs>
    </>
  )
}

export default ReservationsPage
