import React from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  Tag,
  Text,
  Grid,
  GridItem,
  Button,
  ButtonGroup,
  HStack,
  Divider,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { Link } from 'gatsby'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import HotelMap from './hotel-map'
import CancelationModal from './cancelation-modal'
import { useHotelQuery, useReservationQuery } from '~graphql/generated'

interface Props extends RouteComponentProps {
  id?: string
}

const ReservationDetailsPage: React.FC<Props> = ({ id }) => {
  const { data, loading, refetch } = useReservationQuery({
    skip: !id,
    variables: { id: id || '' },
  })

  const { data: hotelData } = useHotelQuery({
    skip: !data?.reservation?.reservation_hotel?.hotel_id,
    variables: { id: data?.reservation?.reservation_hotel?.hotel_id || '' },
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const hotel = hotelData?.hotel
  const reservation = data?.reservation
  const isCanceled = !!reservation?.cancelled_date
  const hotelAddress = reservation?.reservation_hotel?.hotel_address

  return (
    <>
      <Seo title={`Reservation #${id}`} />
      <Heading isLoading={loading}>
        Reservation #{reservation?.reservation_id}
      </Heading>

      <Skeleton w="180px" isLoaded={!loading} mb={[4, 6]}>
        <ButtonGroup size="sm">
          <Button>Contact</Button>
          {!isCanceled && (
            <Button colorScheme="red" onClick={onOpen}>
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </Skeleton>

      <Grid
        gap={[4, 6]}
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
      >
        <GridItem>
          <ContentBox isLoading={loading}>
            <HStack mb={2}>
              <ContentBoxTitle mb={0}>Details</ContentBoxTitle>
              <Tag colorScheme={isCanceled ? 'red' : 'green'}>
                {isCanceled ? 'Canceled' : 'Active'}
              </Tag>
            </HStack>
            <Text fontSize="sm">
              Record locator
              <Text as="kbd" ml={2}>
                {reservation?.record_locator}
              </Text>
            </Text>
            <Text fontSize="sm">
              Itinerary
              <Text as="kbd" ml={2}>
                {reservation?.itinerary_id}
              </Text>
            </Text>
            <Text fontSize="sm">
              Booked on
              <Text as="kbd" ml={2}>
                {reservation?.created_at &&
                  format(
                    new Date(reservation?.created_at),
                    'MMM d yyyy h:mm aaa'
                  )}
              </Text>
            </Text>
            <Text fontSize="sm">
              Booked by
              <Text as={Link} ml={2} to={`/users/${reservation?.user?.id}`}>
                {`${reservation?.user?.first_name} ${reservation?.user?.last_name}`}
              </Text>
            </Text>
            <Text fontSize="sm">
              Credit cost
              <Text as="kbd" ml={2}>
                {reservation?.credit_cost}
              </Text>
            </Text>
            <Divider my={4} />
            <Grid gap={[2, 4]} templateColumns={['repeat(2, 1fr)']}>
              {reservation?.in_date && (
                <GridItem>
                  <ContentBoxTitle mb={2}>In Date</ContentBoxTitle>
                  <Text fontSize="sm">
                    {format(new Date(reservation.in_date), `MMM do yyyy`)}
                  </Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {reservation.in_time}
                  </Text>
                </GridItem>
              )}
              {reservation?.out_date && (
                <GridItem>
                  <ContentBoxTitle mb={2}>Out Date</ContentBoxTitle>
                  <Text fontSize="sm">
                    {format(new Date(reservation.out_date), `MMM do yyyy`)}
                  </Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {reservation.out_time}
                  </Text>
                </GridItem>
              )}
            </Grid>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox p={0} minH="250px">
            {hotel?.latitude && hotel.longitude && (
              <HotelMap lat={hotel?.latitude} lng={hotel?.longitude} />
            )}
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox isLoading={loading}>
            <ContentBoxTitle>Hotel</ContentBoxTitle>
            <Text fontWeight="semibold">
              {reservation?.reservation_hotel?.hotel_name}
            </Text>
            <Text fontSize="sm">{`${hotelAddress?.street_address}, ${hotelAddress?.city}, ${hotelAddress?.state} ${hotelAddress?.postal_code}`}</Text>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox isLoading={loading}>
            <ContentBoxTitle>Room</ContentBoxTitle>
            <Text fontWeight="semibold">
              {reservation?.rooms} {reservation?.reservation_room?.room_name}
            </Text>
            <Text fontSize="sm">
              {`for ${reservation?.adults} adults and ${reservation?.children} children`}
            </Text>
          </ContentBox>
        </GridItem>
      </Grid>

      {reservation && !isCanceled && (
        <CancelationModal
          reservation={reservation}
          isOpen={isOpen}
          onClose={onClose}
          refetch={() => refetch}
        />
      )}
      {/* <ContentBox hasMargin>
        <pre>{JSON.stringify(data, undefined, 2)}</pre>
        <pre>{JSON.stringify(hotelData, undefined, 2)}</pre>
      </ContentBox> */}
    </>
  )
}

export default ReservationDetailsPage
