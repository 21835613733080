import React from 'react'
import { Center, Icon } from '@chakra-ui/react'
import { Marker } from 'react-map-gl'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'

import MapboxMap from '~components/shared/mapbox-map'

interface Props {
  lat: number
  lng: number
}

const HotelMap: React.FC<Props> = ({ lat, lng }) => {
  return (
    <MapboxMap initialViewport={{ zoom: 8, latitude: lat, longitude: lng }}>
      <Marker offsetTop={-20} offsetLeft={-20} latitude={lat} longitude={lng}>
        <Center h="40px" w="40px" bgColor="white" rounded="full" shadow="lg">
          <Icon fontSize="24px" color="#853493" as={HiOutlineOfficeBuilding} />
        </Center>
      </Marker>
    </MapboxMap>
  )
}

export default HotelMap
